import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { animated as a, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';

export const AnimationLoopContainer = ({ animationArray, children, id, component, ...props }) => {
  const configWobbly = { mass: 1, tension: 30, friction: 8 };
  const childrenArray = React.Children.toArray(children);
  const s = (animationArray && animationArray[0].start) || {};
  const s1 = (animationArray && animationArray[1].start) || {};

  const [firstSpring, setFirstImgSpring] = useSpring(() => s);
  const [secondSpring, setSecondImgSpring] = useSpring(() => s1);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  if (inView) {
    setFirstImgSpring({
      from: { ...(animationArray[0].start || {}) },
      to: [{ ...(animationArray[0].update || {}) }],
      config: configWobbly,
    });
    setSecondImgSpring({
      from: { ...(animationArray[1].start || {}) },
      to: { ...(animationArray[1].update || {}) },
      config: configWobbly,
    });
  }

  const retrieveStyle = (idx) => (idx === 0 ? firstSpring : secondSpring);

  const output = childrenArray.map((c, idx) => {
    return cloneElement(<a.div />, {
      style: { ...retrieveStyle(idx) },
      key: `child-${idx}`,
      children: c,
    });
  });

  return cloneElement(component || <div />, {
    ...props,
    ref: ref,
    children: output,
  });
};

AnimationLoopContainer.defaultProps = {
  animationArray: [],
  component: <div />,
};

AnimationLoopContainer.propTypes = {
  animationArray: PropTypes.array,
  children: PropTypes.node,
  component: PropTypes.element,
};
