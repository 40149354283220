import React from 'react';

import Container from '../../container/container';
import OnlyBestForYouIcon from '../../../images/about-section/only-best-for-you-icon.svg';
import AllAroundServiceIcon from '../../../images/about-section/all-around-service-icon.svg';
import HighlySecureWebsiteIcon from '../../../images/about-section/highly-secure-website-icon.svg';
import LightningFastWebsiteIcon from '../../../images/about-section/lightning-fast-website-icon.svg';

import styles from './about-section.module.scss';

export const AboutSection = () => {
  const contentList = [
    {
      title: 'Blitzschnelle Websites',
      desc: 'Blitzschnell, skalierbar und einfach in Verwendung. Erreichen Sie noch mehr Kunden!',
      icon: LightningFastWebsiteIcon,
      alt: 'Blitzschnelle Websites',
    },
    {
      title: 'Hochsichere Website',
      desc: 'Deutsche Sicherheits- und Datenschutzstandards. Schützen Sie Ihre Daten und die Daten Ihrer Kunden!',
      icon: HighlySecureWebsiteIcon,
      alt: 'Hochsichere Website',
    },
    {
      title: 'Rundum Service',
      desc:
        'Lehnen Sie sich zurück - Design, Programmierung und Pflege Ihrer Website. Das alles erledigen wir für Sie.',
      icon: AllAroundServiceIcon,
      alt: 'Rundum Service',
    },
    {
      title: 'Nur das Beste für Sie',
      desc: 'Professionelle und hoch bewertete Websites zu attraktiven Preisen',
      icon: OnlyBestForYouIcon,
      alt: 'Nur das Beste für Sie',
    },
  ];

  return (
    <Container className={styles.container}>
      <h2 className={styles.heading}>Alles, was Sie für Ihren Erfolg benötigen.</h2>
      <div className={styles.wrapper}>
        {contentList.map(({ title, desc, icon, alt }, i) => (
          <div key={i} className={styles.contentHolder}>
            <div className={styles.imageWrapper}>
              <img src={icon} alt={alt} />
            </div>
            <h3 className={styles.title}>{title}</h3>
            <p>{desc}</p>
          </div>
        ))}
      </div>
    </Container>
  );
};
