import React from 'react';
import Container from '../container/container';
import clsx from 'clsx';
import CtaButton from '../button/cta-button';
import styles from './header-lp.module.scss';

const HeaderLandingPage = () => {
  return (
    <header className={clsx(styles.header)}>
      <Container className={styles.container} variant="div">
        <CtaButton
          cta={{ to: '/webdesign/#contact-section', label: 'Jetzt Anfragen' }}
          useIconOnMobile={true}
          className={styles.ctaWrapper}
        />
      </Container>
    </header>
  );
};

export default HeaderLandingPage;
