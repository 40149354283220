import React from 'react';
import clsx from 'clsx';
import Container from '../../container/container';
import { graphql, useStaticQuery } from 'gatsby';
import { NonStretchedImage } from '../../image/non-stretched-image';
import styles from './client-logos-section.module.scss';

export function ClientLogosSection({ className = '' }) {
  const {
    logoAlexHauk: {
      childImageSharp: { fluid: logoAlexHauk },
    },
    logoMaKaBau: {
      childImageSharp: { fluid: logoMaKaBau },
    },
    logoProfilium: {
      childImageSharp: { fluid: logoProfilium },
    },
    logoPositivFitness: {
      childImageSharp: { fluid: logoPositivFitness },
    },
    logoRheinstettenTv: {
      childImageSharp: { fluid: logoRheinstettenTv },
    },
  } = useStaticQuery(
    graphql`
      query {
        logoAlexHauk: file(relativePath: { eq: "clients/logo-alex-hauk.png" }) {
          childImageSharp {
            fluid(maxHeight: 151, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
        logoMaKaBau: file(relativePath: { eq: "clients/logo-ma-ka-bau.png" }) {
          childImageSharp {
            fluid(maxHeight: 151, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
        logoProfilium: file(relativePath: { eq: "clients/logo-profilium.png" }) {
          childImageSharp {
            fluid(maxHeight: 151, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
        logoPositivFitness: file(relativePath: { eq: "clients/logo-positiv-fitness.png" }) {
          childImageSharp {
            fluid(maxHeight: 151, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
        logoRheinstettenTv: file(relativePath: { eq: "clients/logo-rheinstetten-tv.png" }) {
          childImageSharp {
            fluid(maxHeight: 151, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `
  );

  const clients = [
    {
      logo: logoPositivFitness,
      title: 'Positiv Fitness GmbH',
    },
    {
      logo: logoAlexHauk,
      title: 'Alex Hauk Performance Support',
    },
    {
      logo: logoMaKaBau,
      title: 'Ma-Ka-Bau',
    },
    {
      logo: logoProfilium,
      title: 'Profilium GmbH',
    },
    {
      logo: logoRheinstettenTv,
      title: 'Rheinstetten Tv',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Container className={clsx(styles.container, className)}>
        {clients.map((client, idx) => (
          <div className={styles.logo} key={`client-logo-${idx}`}>
            <NonStretchedImage fluid={client.logo} alt={client.title} className={styles.logoImage} />
          </div>
        ))}
      </Container>
    </div>
  );
}
