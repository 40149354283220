import React from 'react';
import Container from '../../container/container';
import styles from './our-service-section.module.scss';

import IconWebDesign from '../../../images/icons/our-services/icon-webdesign.svg';
import IconOnlineShop from '../../../images/icons/our-services/icon-online-shop.svg';
import IconSearch from '../../../images/icons/our-services/icon-search.svg';
import IconOnlineMarketing from '../../../images/icons/our-services/icon-online-marketing.svg';

const OurServiceSection = () => {
  const services = [
    {
      icon: { src: IconWebDesign },
      title: 'web design',
      description:
        'Ihre Webseite ist Ihr Tor zu neuen Kunden, Ihr Aushängeschild, Ihre 24/7 Präsens im Internet. Starten Sie durch in ein neues Zeitalter, mit einer kreativen und verkaufsstarken Webseite von unserer Webdesign Agentur Hamburg.',
      alt: 'icon',
    },
    {
      icon: { src: IconOnlineMarketing },
      title: 'WEB ANWENDUNGEN',
      description:
        ' Serverless Webanwendungen basierend auf der modernsten Cloud-Technologie bedeuten für Sie schnelles Time-To-Market und sicheres Investment bei maximalen Nutzen! Individuell auf Sie zugeschnittene Anwendungen helfen Ihnen Ihr Geschäftspotenzial zu steigern. Profitieren Sie von userer über 17 Jahre Erfahrung im Entwickeln der Enterprise-Webanwendungen.',
      alt: 'icon',
    },
    {
      icon: { src: IconSearch },
      title: 'SUCHMASCHINEN- OPTIMIERUNG',
      description:
        'Es gibt im Internet sehr viel zur Auswahl. Wir helfen Ihnen, durch Suchmaschinenoptimierung im Internet gefunden zu werden. Und zwar genau von den Kunden, die das Brauchen was Sie anbieten.',
      alt: 'icon',
    },
    {
      icon: { src: IconOnlineShop },
      title: 'online shop',
      description:
        'Wir helfen Ihnen bei dem Aufbau Ihres eigenen erfolgreichen Onlineshops. Verkaufen Sie Ihre Produkte einfach Online um mehr Umsatz zu generieren. Mit uns haben sie den idealen Einstieg in den Bereich E-Commerce.',
      alt: 'icon',
    },
  ];

  return (
    <div className={styles.ourServicesWrapper}>
      <Container>
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>Unsere Leistungen</h2>
          <p className={styles.subTitle}>
            Bei Evelan finden Sei eine Agentur, die sich auf Webdesign und moderne Cloud-Web-Anwendungen spezialisiert
            hat. Je nach Zielstellung leisten wir für Sie alle Services rund um Ihre neue Internetseite! Sie bekommen
            bei uns eine individuelle Betreuung während der gesamten Projektphase und darüber hinaus.
          </p>
        </div>
        <div className={styles.servicesHolder}>
          {services.map((services, idx) => (
            <div className={styles.servicesBox} key={`services-${idx}`}>
              <div className={styles.servicesImg}>
                <img src={services.icon.src} alt={services.alt} />
              </div>
              <div className={styles.servicesInfo}>
                <h3 className={styles.servicesTitle}>{services.title}</h3>
                <p className={styles.servicesDescription}>{services.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default OurServiceSection;
