import React, { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import Img from 'gatsby-image';

import Container from '../container/container';
import { InternalLinkButton } from '../button/internal-link-button';
import HeaderLandingPage from '../header/header-lp';
import TrailAnimation from '../animate/TrailAnimation';
import { AnimationLoopContainer } from './hero-animated-images';

import CheckIcon from '../../images/icons/check-icon-primary.svg';
import logoNormal from '../../images/logo/evelan-gmbh.svg';

import styles from './hero-contact-form.module.scss';

const contentList = [
  {
    title: '100%',
    description: 'Zufriedenheitsgarantie',
    subDescription: 'Wir entwickeln, bis Sie zufrieden sind',
  },
  {
    title: '100%',
    description: 'Erfolg',
    subDescription: 'Ihre Kunden werden Ihre Webseite lieben',
  },
  {
    title: `Über ${new Date().getFullYear() - 2003}`,
    description: 'Jahre',
    subDescription: 'Expertise und Know-how',
  },
];

const buttonContent = {
  home: {
    text: 'Projektanfrage stellen',
    link: '/projektanfrage/#inquiry-header',
  },
  webdesign: {
    text: 'Jetzt Anfragen',
    link: '/webdesign/#contact-section',
  },
};

export const HeroContactForm = ({ page, showHeader = true }) => {
  const {
    websitesFirstColumn: {
      childImageSharp: { fluid: websitesFirstColumn },
    },
    websitesSecondColumn: {
      childImageSharp: { fluid: websitesSecondColumn },
    },
    websitesHorizontalFirstColumn: {
      childImageSharp: { fluid: websitesHorizontalFirstColumn },
    },
    websitesHorizontalSecondColumn: {
      childImageSharp: { fluid: websitesHorizontalSecondColumn },
    },
  } = useStaticQuery(
    graphql`
      query {
        websitesFirstColumn: file(relativePath: { eq: "webseite-erstellen/hero-contact-website-1.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        websitesSecondColumn: file(relativePath: { eq: "webseite-erstellen/hero-contact-website-2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        websitesHorizontalFirstColumn: file(
          relativePath: { eq: "webseite-erstellen/hero-contact-website-horizontal-1.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        websitesHorizontalSecondColumn: file(
          relativePath: { eq: "webseite-erstellen/hero-contact-website-horizontal-2.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  const scrollCache = useRef({ pos: 0, debounce: null });
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      const { debounce } = scrollCache.current;

      if (debounce) {
        window.cancelAnimationFrame(debounce);
      }

      scrollCache.current.debounce = window.requestAnimationFrame(function () {
        const lastPosition = scrollCache.current.pos;
        const y = window.pageYOffset;
        if (lastPosition < y) {
          if (!scrolled && y >= 100) {
            setScrolled(true);
          }
        } else {
          if (scrolled) {
            setScrolled(false);
          }
        }
        scrollCache.current.pos = window.pageYOffset;
      });
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolled, scrolled]);

  return (
    <div className={styles.wrapper}>
      <Container className={styles.container}>
        {showHeader && (
          <>
            <div className={styles.logo}>
              <img src={logoNormal} alt="Evelan GmbH" />
            </div>
            <div className={clsx(styles.headerWrapper, scrolled && styles.hideHeader)}>
              <HeaderLandingPage />
            </div>
          </>
        )}
        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <TrailAnimation perWord variant="SlideUpClip" component={<h1 />}>
              Moderne Websites, die
            </TrailAnimation>
            <TrailAnimation
              delayBeforeStart={350}
              perWord
              variant="SlideUpClip"
              component={<h1 className={styles.headerOrange} />}
            >
              mehr Kunden erreichen
            </TrailAnimation>
            <TrailAnimation variant="SlideUpClip" delayBeforeStart={300} delayBetweenItems={200}>
              <h5>
                Skalieren Sie Ihre Reichweite und lassen Sie ihre Website zum Teil ihrer Digital-Strategie werden.
              </h5>
              <p>
                Gemeinsam erstellen wir einen Schritt-für-Schritt-Plan, um Ihren Erfolg im Internet sicherzustellen und
                Sie online zu vermarkten.
              </p>
              <div className={styles.buttonWrapper}>
                <InternalLinkButton to={buttonContent[page].link} className={styles.ctaButton} color="highlight">
                  {buttonContent[page].text}
                </InternalLinkButton>
              </div>
            </TrailAnimation>

            <ul className={styles.checkList}>
              {contentList.map((item, key) => (
                <TrailAnimation
                  key={key}
                  delayBeforeStart={150 * (key + 1)}
                  variant="SlideRightToLeftClip"
                  component={<li key={key} className={styles.contentItem} />}
                >
                  <div className={styles.contentItemWrapper}>
                    <img className={styles.checkIcon} src={CheckIcon} alt="Primary Checkmark" />
                    <div>
                      <p className={styles.contentTitle}>
                        <span>{item.title}</span> {item.description}
                      </p>
                      <p className={styles.contentDescription}>{item.subDescription}</p>
                    </div>
                  </div>
                </TrailAnimation>
              ))}
            </ul>
          </div>
          <div className={styles.websiteBackgroundImageWrapper}>
            <AnimationLoopContainer
              animationArray={[
                {
                  start: { transform: `translateX(0em) translateY(-120em)` },
                  update: { transform: `translateX(0em) translateY(-10em)` },
                },
                {
                  start: { transform: `translateX(0em) translateY(180em)` },
                  update: { transform: `translateX(0em) translateY(70em)` },
                },
              ]}
              component={<div className={styles.websiteBackgroundImage} />}
            >
              <Img fluid={websitesFirstColumn} alt="Websites First Column" />
              <Img fluid={websitesSecondColumn} alt="Websites Second Column" />
            </AnimationLoopContainer>
            <AnimationLoopContainer
              animationArray={[
                {
                  start: { transform: `translateX(60em)` },
                  update: { transform: `translateX(10em)` },
                },
                {
                  start: { transform: `translateX(-80em)` },
                  update: { transform: `translateX(-10em)` },
                },
              ]}
              component={<div className={styles.websiteBackgroundImageTablet} />}
            >
              <Img fluid={websitesHorizontalFirstColumn} alt="Websites Horizontal First Column" />
              <Img fluid={websitesHorizontalSecondColumn} alt="Websites Horizontal Second Column" />
            </AnimationLoopContainer>
          </div>
        </div>
      </Container>
    </div>
  );
};
